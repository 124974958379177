<template>
  <div>
    <ModuleComponent :module-data="moduleData" @changed="item = $event">
      <template v-slot:customColumn_active="{ rowItem }">
        <TableStatusColumn v-model="rowItem.active"></TableStatusColumn>
      </template>
      <template v-slot:tableFilters="{ filters }">
        <b-container fluid>
          <b-row>
            <b-col cols="3" sm="3" md="3">
              <b-form-group :label="t('GLOBAL.NAME')">
                <b-form-input
                  name="name"
                  v-model="filters.name.value"
                  :placeholder="t('GLOBAL.NAME')"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="3" sm="3" md="3">
              <b-form-group :label="t('GLOBAL.CODE')">
                <b-form-input
                  v-model="filters.code.value"
                  :placeholder="t('GLOBAL.CODE')"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="3" sm="3" md="3">
              <b-form-group :label="t('GLOBAL.PRODUCT')">
                <v-select2
                  v-model="filters.productId.value"
                  endPoint="products"
                  :placeholder="t('GLOBAL.ALL')"
                ></v-select2>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </template>
      <template v-slot:form="{ item }">
        <b-row>
          <b-col cols="12" sm="8" md="8">
            <b-form-group :label="t('GLOBAL.NAME')">
              <b-form-input
                name="name"
                v-model="item.name"
                :placeholder="t('GLOBAL.NAME')"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="4" md="4">
            <b-form-group :label="t('GLOBAL.CODE')">
              <b-form-input
                name="code"
                v-model="item.code"
                :placeholder="t('GLOBAL.CODE')"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="12" md="12">
            <b-form-group :label="t('GLOBAL.PRODUCT')">
              <v-select2
                v-model="item.productId"
                :text="item.product ? item.product.name : ''"
                endPoint="products"
                :placeholder="t('GLOBAL.PRODUCT')"
              ></v-select2>
            </b-form-group>
          </b-col>
          <b-col cols="2" sm="3" md="2">
            <b-form-group :label="t('GLOBAL.STATUS')">
              <b-form-checkbox v-model="item.active" switch>
                {{ t("GLOBAL.ACTIVE") }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
      </template>
    </ModuleComponent>
  </div>
</template>

<script>
// http://core.datadns.net:5000/swagger/index.html#model-ProductKind
export default {
  name: "productKinds",
  data() {
    return {
      item: {},
      moduleData: {
        name: "productKinds",
        primaryKey: "id",
        table: {
          sortBy: "product.name",
          sortDesc: false,
          graphqlQuery: `
							id,name,code,active,product{name}
						`,
          headers: [
            { text: "ID", value: "id", width: "70" },
            { text: this.t("GLOBAL.PRODUCTNAME"), value: "product.name" },
            { text: this.t("GLOBAL.NAME"), value: "name" },
            { text: this.t("GLOBAL.CODE"), value: "code" },
            {
              text: this.t("GLOBAL.STATUS"),
              value: "active",
              width: "60",
              align: "center"
            }
          ],
          customColumns: ["active"],
          filters: {
            name: {
              type: "string",
              searchBy: "beginsWith",
              value: null
            },
            code: {
              type: "string",
              searchBy: "contains",
              value: null
            },
            productId: {
              type: "number",
              value: null
            }
          }
        },
        form: {
          maxWidth: "80%"
        }
      }
    };
  }
};
</script>
